<template>
  <div class="mt0 px40 pt20">
    <page-header
      class="relative"
      style="right: 40px;"
      header="Admin Panel"
      :tabs="adminTabs"
      :headerStyles="{ margin: '16px 0px 16px 0px' }"
      @tabClicked="state = arguments[0].value"
    />
    <!-- tabs class="mt20" 
			@tabClicked="state = arguments[0].value" 
			:tabs="adminTabs"></tabs -->
    <div v-if="state == 'oldRegistrations'" class="mt20 max spaced flex">
      <search-dropdown
        ref="oldRegDropdown"
        :key="state"
        style="width: 300px;"
        placeholder="Select Event"
        @selected="activeEvent = arguments[0]"
      >
        <div
          class="item"
          v-for="e in events"
          :key="e.id"
          :value="e.eventId"
          :data-value="e.eventId"
        >
          {{ e.name }}
        </div>
      </search-dropdown>
      <p>
        Currently showing <span class="bold">{{ activeOldEvent.name }}</span>
      </p>
    </div>

    <div v-if="state == 'eventRegistrations'" class="mt20">
      <search-dropdown
        :key="state"
        class="mr12"
        style="width: 300px"
        @selected="activeMasterEvent = arguments[0]"
      >
        <div
          class="item"
          v-for="e in masterEvents"
          :key="e.id"
          :value="e.id"
          :data-value="e.id"
        >
          {{ e.name }}
        </div>
      </search-dropdown>
      <search-dropdown
        :key="state"
        style="width: 300px;"
        placeholder="Select Event"
        @selected="active = arguments[0]"
      >
        <div
          class="item"
          v-for="e in filteredEvents"
          :key="e.id"
          :value="e.id + '-' + e.type"
          :data-value="e.id + '-' + e.type"
        >
          {{ e.Title || e.name }}
        </div>
      </search-dropdown>
      <select v-model="selectedYear" class="ml12">
        <option value="" selected>Select Year</option>
        <option value="active">Active</option>
        <option v-for="year in eventYears" :key="year" :value="year">{{
          year
        }}</option>
      </select>
    </div>
    <div v-if="state == 'quizRankings'" class="mt20">
      <search-dropdown
        :key="state"
        style="width: 300px;"
        placeholder="Select Quiz"
        @selected="active = arguments[0]"
      >
        <div
          class="item"
          v-for="e in quizzes"
          :key="e.id"
          :value="e.id"
          :data-value="e.id"
        >
          {{ e.title }}
        </div>
      </search-dropdown>
    </div>
    <quiz-ranking
      :hideBackArrow="true"
      style="margin-left: -40px;"
      :_id="active"
      v-if="state == 'quizRankings'"
    />
    <registrations
      style="margin-left: -40px;"
      :_id="activeTypeId.id"
      v-if="state == 'eventRegistrations' && activeTypeId.type != 'old'"
    />
    <div v-if="state == 'eventRegistrations' && activeTypeId.type == 'old'">
      <div class="mt20 flex">
        <button class="mlauto" @click="exportCsv">
          Download
        </button>
      </div>

      <table class="ui celled table">
        <thead>
          <tr>
            <th>Team</th>
            <th>Name</th>
            <th>Email</th>
            <th>Contact</th>
            <th>Type</th>
            <th>Field</th>
            <th>Institute</th>
          </tr>
        </thead>
        <tr v-for="(t, i) in teams" :key="t + ' - ' + i">
          <td>Team - {{ t }}</td>
          <td
            v-for="(field, j) in [
              'membr_name',
              'membr_email',
              'contact',
              'type',
              'field',
              'institute'
            ]"
            :key="j"
          >
            <span v-for="(m, ix) in getMembers(t)" :key="ix">
              {{ m[field] }}
              <br />
            </span>
          </td>

          <!-- <td v-for="(m, i) in getMembers(t)" :key="i">
          {{ m.membr_name }} <br />
          {{ m.membr_email }} <br />
          {{ m.contact }} <br />
          {{ m.type }} <br />
          {{ m.field }} <br />
          {{ m.institute }} <br />
        </td> -->
        </tr>
      </table>
    </div>

    <massmailer style="margin-left: -40px;" v-if="state == 'massmailer'" />
    <bulkResultUpload
      style="margin-left: -40px;"
      v-if="state == 'bulkResultUpload'"
    />
    <universities v-if="state == 'universities'" class="mt40" />
    <unlisted-universities
      v-if="state == 'unlistedUniversities'"
      class="mt40"
    />
    <acegate-enrollees v-if="state == 'acegate'" />
    <user-search :showCleanup="showCleanup" v-if="state == 'userSearch'" />
    <div v-if="state == 'actions'">
      <button class="mt20 primary" @click="refreshQuestionCategories">
        Refresh Question Categories
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import quizRanking from "./QuizRanking";
import registrations from "./Registrations";
import bulkResultUpload from "./BulkResultUpload";
import Massmailer from "./Massmailer";
import universities from "./universities";
import userSearch from "./user-search";
import pageHeader from "../components/page-header";
import acegateEnrollees from "../components/acegate-enrollees";
import searchDropdown from "../components/search-dropdown";
import unlistedUniversities from "./unlistedUniversities";
export default {
  name: "Admin",
  components: {
    Massmailer,
    bulkResultUpload,
    pageHeader,
    registrations,
    quizRanking,
    universities,
    acegateEnrollees,
    unlistedUniversities,
    searchDropdown,
    userSearch
  },
  data() {
    return {
      access: {},
      state: "userSearch",
      activeEvent: "",
      active: "",
      events: [],
      currEvents: [],
      quizzes: [],
      registrations: [],
      regs: [],
      teams: [],
      eventYears: [],
      selectedYear: "",
      masterEvents: [],
      activeMasterEvent: ""
    };
  },
  mounted() {
    if (this.$route.query.state) {
      this.state = this.$route.query.state;
    }
    if (this.$route.query.activeEvent) {
      this.activeEvent = this.$route.query.activeEvent;
      if (this.state != "quizzes") this.active = this.activeEvent + "-old";
      this.getRegs();
    }
    this.getEvents();
    this.$req
      .get("access-control", this.$bus.headers)
      .then(r => {
        this.access = r.data;
      })
      .catch(e => console.log(e));
  },
  watch: {
    active: function() {
      if (this.activeTypeId.type == "old") {
        this.getRegs();
      }
    },
    state: function() {
      /* eslint-disable */
      $(".ui.dropdown")?.dropdown();
      // if (v == 'oldRegistrations') {this.activeEvent = ''}
    }
  },
  computed: {
    activeOldEvent: function() {
      let evnt = this.events.find(ev => ev.eventId == this.activeEvent);
      return evnt || {};
    },
    activeTypeId: function() {
      if (this.active.includes("-")) {
        let typeId = this.active.split("-");
        return {
          id: typeId[0],
          type: typeId[1]
        };
      }
      return {
        id: "",
        type: ""
      };
    },
    showCleanup: function() {
      let v = "collegeCleanup";
      let acc = (this.access[v] || []).map(u => u.email);
      let em = this.$bus.user.email;
      return acc.includes(em);
    },
    adminTabs: function() {
      let tabs = [
        {
          label: "User Search",
          value: "userSearch"
        },
        {
          label: "Event Registrations",
          value: "eventRegistrations"
        },
        {
          label: "Quiz Rankings",
          value: "quizRankings"
        },
        {
          label: "Massmailer",
          value: "massmailer"
        },
        {
          label: "Bulk Data Upload",
          value: "bulkResultUpload"
        },
        {
          label: "Universities",
          value: "universities"
        },
        {
          label: "Acegate Enrollees",
          value: "acegate"
        },
        {
          label: "Actions",
          value: "actions"
        }
      ];
      // return tabs;
      return tabs.filter(t => {
        let v = t.value;
        let em = this.$bus.user.email;
        let acc = (this.access[v] || []).map(u => u.email);

        if (acc.includes(em)) {
          if (this.state == "") {
            this.state = v;
          }
          return true;
        }
      });
    },
    filteredEvents: function() {
      let events = [
        ...this.currEvents,
        ...this.events.map(ev => {
          ev["type"] = "old";
          return ev;
        })
      ];
      if (this.selectedYear) {
        events = events.filter(
          ev => moment(ev.Start).format("YYYY") == this.selectedYear
        );
      }
      if (this.activeMasterEvent) {
        events = events.filter(ev => {
          let mEvent = ev.master_event || {};
          return mEvent.id == this.activeMasterEvent;
        });
      }
      return events.sort((e1, e2) => e1.Title - e2.Title);
    }
  },
  methods: {
    eventSelected: function(eventId) {
      // console.log("Selected", eventId);
    },
    check: function(ix) {
      // console.log("Arguments", ix);
    },
    refreshQuestionCategories: function() {
      this.$req
        .post("updateCategories", {}, this.$bus.headers)
        .then(r => console.log(r))
        .catch(e => console.log(e));
    },
    getEvents: function() {
      this.$req
        .get("old-events?_limit=-1", this.$bus.headers)
        .then(r => {
          this.events = r.data;
        })
        .catch(e => console.log(e));
      this.$req
        .get("master-events?_limit=-1")
        .then(r => {
          this.masterEvents = r.data;
          // console.log(this.masterEvents);
        })
        .catch(e => console.log(e));
      this.$req
        .get("events?_limit=-1", this.$bus.headers)
        .then(r => {
          this.currEvents = r.data.sort((s1, s2) => s1 - s2);
          this.eventYears = r.data
            .map(ev => moment(ev.Start).format("YYYY"))
            .filter((v, i, s) => s.indexOf(v) == i)
            .sort()
            .reverse();
        })
        .catch(e => console.log(e));
      this.$req
        .post("runQuery", {
          query: "quizzes"
        })
        .then(r => {
          this.quizzes = r.data;
        })
        .catch(e => console.log(e));
    },
    getRegs: async function() {
      this.$req
        .get(
          `getOldEventRegistrations?event=${this.activeTypeId.id}`,
          this.$bus.headers
        )
        .then(r => {
          const regs = r.data;
          this.teams = regs
            .map(reg => reg.ergnt_teamno)
            .filter((v, i, s) => s.indexOf(v) == i)
            .sort((t1, t2) => parseInt(t1) - parseInt(t2));
          this.$req
            .post(
              "adminQuery",
              {
                query: "usersByEmail",
                emails: regs.map(r => r.membr_email)
              },
              this.$bus.headers
            )
            .then(resp => {
              const users = resp.data;
              this.regs = regs.map(reg => {
                const user = users.find(u => u.email == reg.membr_email);
                return {
                  ...reg,
                  ...user
                };
              });
            })
            .catch(e => e);
        })
        .catch(e => console.log(e));
    },
    getMembers: function(team) {
      const regs = this.regs.filter(r => r.ergnt_teamno == team);
      return regs;
    },
    downloadCsv: function(json) {
      var csv = [];
      var maxlen = Math.max(...json.map(j => Object.keys(j).length));
      var maxjson = json.filter(j => Object.keys(j).length == maxlen);
      if (json.length) {
        var keys = Object.keys(maxjson[0]);
        csv.push(keys.join(","));
        json.forEach(item => {
          let vals = keys.map(key => item[key] || "");
          csv.push(vals.join(","));
        });
      }

      csv = csv.join("\n");
      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      hiddenElement.target = "_blank";
      hiddenElement.download = this.activeEvent.title + ".csv";
      hiddenElement.click();
    },
    exportCsv: function() {
      let fields = [
        "membr_name",
        "membr_email",
        "contact",
        "type",
        "field",
        "institute"
      ];
      const teams = this.regs
        .map(t => t.ergnt_teamno)
        .filter((v, i, s) => s.indexOf(v) == i);

      let json = teams.map(t => {
        let obj = {};
        const members = this.getMembers(t);
        // obj["id"] = members[0];
        // obj["registration date"] = r.created_at;
        for (let [i, u] of members.entries()) {
          for (let f of fields) {
            obj[f + ":" + i] = '"' + u[f] + '"';
          }
        }
        return obj;
      });
      this.downloadCsv(json);
      return json;
    }
  }
};
</script>
