<template>
  <div>
    <div class="flex wrap">
      <span class="mr12 mt12 vertical flex">
        <div class="vertical flex">
          <label for="" style="color: transparent">Search</label>
          <div
            style="border: 1px solid #ccc; padding: 0 12px; margin-top: 4px;"
          >
            <i class="ml8 search icon"></i>
            <input
              placeholder="Search Name or Email"
              type="text"
              class="br4 myauto search_input"
              @keyup.enter="searchUsers(0); page = 0;"
              @change="search = $event.target.value"
            />
          </div>
        </div>
      </span>
      <span class="mr12 mt12 vertical flex">
        <label>Type</label>
        <select class="mt4" v-model="form.type" placeholder="User Type">
          <option selected value="">All</option>
          <option v-for="t in userTypes" :key="t">{{ t }}</option>
        </select>
      </span>
      <span class="mr12 mt12 vertical flex">
        <label>Field of Study</label>
        <select class="mt4" v-model="form.field">
          <option selected value="">All</option>
          <option v-for="f in filteredFields" :key="f" :value="f">{{
            f
          }}</option>
        </select>
      </span>
      <span class="mr12 mt12 vertical flex">
        <label class="mt4">Education Level</label>
        <select v-model="form.levelOfEducation">
          <option selected value="">All</option>
          <option v-for="l in educationLevels" :key="l" :value="l">{{
            l
          }}</option>
        </select>
      </span>
      <span class="mr12 mt12 vertical flex">
        <label class="mt4">YoG eq</label>
        <select class="wfill" v-model="yogeq">
          <option selected value="">=</option>
          <option value="_gte">&#62;=</option>
          <option value="_lte">&#60;=</option>
        </select>
      </span>

      <span class="mr12 mt12 vertical flex">
        <label>Year of Graduation</label>
        <select class="mt4 wfill" v-model="form.yearOfGraduation">
          <option selected value="">All</option>
          <option v-for="y in years" :key="y" :value="y">{{ y }}</option>
        </select>
      </span>
      <span class="mr12 mt12 vertical flex">
        <label class="mt4">Country</label>
        <select class="wfill" v-model="form.country">
          <option selected value="">India</option>
          <option value="neq">International</option>
        </select>
      </span>
      <span class="mr12 mt12 vertical flex">
        <label>Zone</label>
        <select class="mt4 wfill" v-model="form.zone">
          <option selected value="">All</option>
          <option v-for="z in zones" :key="z" :value="z">{{ z }}</option>
        </select>
      </span>
      <span class="mr12 mt12 vertical flex">
        <label>State</label>
        <select
          :key="filteredStates.length"
          class="mt4 wfill"
          v-model="form.state"
        >
          <option selected value="">All</option>
          <option v-for="z in filteredStates" :key="z" :value="z">{{
            z
          }}</option>
        </select>
      </span>
      <span class="mr12 mt12 vertical flex">
        <label>City</label>
        <search-dropdown v-if="filteredCities.length > 0" @selected="form.city = arguments[0]">
          <div
            class="item"
            v-for="u in filteredCities"
            :key="u"
            :data-value="u"
          >
            {{ u }}
          </div>
        </search-dropdown>
        <!-- select class="mt4 wfill" v-model="form.city">
					<option selected value="">All</option>
					<option v-for="z in filteredCities" :key="z" :value="z">{{z}}</option>
				</select -->
      </span>
      <span class="mr12 mt12 vertical flex">
        <label>Institute</label>
        <search-dropdown v-if="filteredUnis.length > 0" @selected="form.institute = arguments[0]">
          <div
            class="item"
            v-for="u in filteredUnis"
            :key="u.id"
            :data-value="u.name + ', ' + u.city"
          >
            {{ u.name + ", " + u.city }}
          </div>
        </search-dropdown>
      </span>
      <span class="mr12 mt12 vertical flex">
        <label>Connectaid Job Seeker</label>
        <input type="checkbox" v-model="form.connectaidJobSeeker" />
      </span>
      <span class="mr12 mt12 vertical flex">
        <label>Acegate Learner</label>
        <input type="checkbox" v-model="form.acegateLearner" />
      </span>
      <span class="mr12 mt12 vertical flex">
        <label>Accepting Massmail</label>
        <input type="checkbox" v-model="form.acceptingMassmail" />
      </span>
    </div>
    <button
      class="mt12 brown500"
      @click="
        searchUsers(0);
        page = 0;
      "
    >
      {{ loading ? 'Searching...' : 'Search' }}
    </button>
    <button class="mt12 ml8 brown400" @click="clearForm">Clear</button> <br />
    <div v-if="showCleanup">
      <search-dropdown
        class="mt12"
        @selected="replace.institute = arguments[0]"
      >
        <div
          class="item"
          v-for="u in filteredUnis"
          :key="u.id"
          :data-value="u.name + ', ' + u.city"
        >
          {{ u.name + ", " + u.city }}
        </div>
      </search-dropdown>
      <search-dropdown
        class="ml12 mt12"
        @selected="replace.city = arguments[0]"
      >
        <div class="item" v-for="u in filteredCities" :key="u" :data-value="u">
          {{ u }}
        </div>
      </search-dropdown>
      <select class="ml12 mt12" v-model="replace.acceptingMassmail">
        <option value="">Massmail</option>
        <option value="1">True</option>
        <option value="0">false</option>
      </select>
      <select class="ml12 mt12" v-model="replace.type">
        <option value="">Year of Graduation</option>
        <option value="Student">Student</option>
        <option value="Professional">Professional</option>
        <option value="Faculty">Faculty</option>
      </select>
      <button class="ml12 brown500" @click="downloadUsers(true)">
        Replace For Search
      </button>
    </div>
    <div class="max spaced flex">
      <pagination
        style="width: auto"
        class="mrauto my12"
        :pages="pages"
        @gotoPage="page = arguments[0]"
      />
      <p class="mrauto fs14" style="margin: auto 0 auto auto">
        Page: {{ page + 1 }} | {{ page * 30 + 1 }} - {{ page * 30 + 30 }} of
        {{ count }} users
      </p>
      <button
        @click="downloadUsers(false)"
        class="ml20 p4 myauto"
        style="padding: 12px; height: 40px;"
      >
        <i style="margin: 0px;" class="download icon"></i>
      </button>
    </div>
    <table class="ui celled table">
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Email</th>
          <th>Contact</th>
          <th>Type</th>
          <th>Field</th>
          <th>Institute</th>
          <th>Place of Work</th>
          <th>Location</th>
          <th>YOG</th>
          <th>Massmail</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="u in users">
          <tr :key="'user-' + u.id">
            <td>{{ u.id }}</td>
            <td>
              {{ u.name }}
              <input
                v-if="edit == u.id"
                style="width: 100%"
                @change="updateUser(u.id, { name: $event.target.value })"
                :value="u.name"
              />
            </td>
            <td>{{ u.email }}</td>
            <td>
              {{ u.contact }}
              <input
                v-if="edit == u.id"
                @change="updateUser(u.id, { contact: $event.target.value })"
                :value="u.contact"
              />
            </td>
            <td>
              {{ u.type }}
              <select
                v-if="edit == u.id"
                style="width: 100%"
                @change="updateUser(u.id, { type: $event.target.value })"
                :value="u.type"
              >
                <option value="Student">Student</option>
                <option value="Professional">Professional</option>
                <option value="Faculty">Faculty</option>
              </select>
            </td>
            <td>
              {{ u.field }}
              <select
                v-if="edit == u.id"
                style="width: 100%"
                class="mt4"
                :value="u.field"
                @change="updateUser(u.id, { field: $event.target.value })"
              >
                <option v-for="f in filteredFields" :key="f" :value="f">{{
                  f
                }}</option>
              </select>
            </td>
            <td>
              {{ u.institute }}
              <span v-if="u.notListedInstitute">
                <br />
                <p style="font-size: 12px; color: #aaa">
                  Not Listed - {{ u.notListedInstitute }}
                </p>
              </span>
              <search-dropdown
                v-if="edit == u.id && filteredUnis.length > 0"
                style="width: 100%"
                class="mt12"
                @selected="updateUser(u.id, { institute: arguments[0] })"
              >
                <div
                  class="item"
                  v-for="u in filteredUnis"
                  :key="u.id"
                  :data-value="u.name + ', ' + u.city"
                >
                  {{ u.name + ", " + u.city }}
                </div>
              </search-dropdown>
            </td>
            <td>
              {{ u.companyName }}
              <input
                v-if="edit == u.id"
                style="width: 100%"
                :value="u.companyName"
                @change="updateUser(u.id, { companyName: $event.target.value })"
              />
            </td>
            <td>
              {{ u.location }}
              <input
                v-if="edit == u.id"
                style="width: 100%"
                :value="u.location"
                @change="updateUser(u.id, { location: $event.target.value })"
              />
            </td>
            <td>
              {{ u.yearOfGraduation }}
              <input
                v-if="edit == u.id"
                style="width: 100%"
                type="number"
                :value="u.yearOfGraduation"
                @change="
                  updateUser(u.id, { yearOfGraduation: $event.target.value })
                "
              />
            </td>
            <td>
              <input
                :disabled="edit != u.id"
                :checked="u.acceptingMassmail"
                @click="
                  updateUser(u.id, { acceptingMassmail: $event.target.checked })
                "
                type="checkbox"
              />
              {{ u.acceptingMassmail }}
            </td>
            <td class="flex">
              <button
                style="padding: 6px;"
                class="ml12"
                @click="toggleOpen(u.id)"
              >
                <i style="font-size: 12px;" class="info icon"></i>
              </button>
              <button
                style="padding: 6px;"
                class="ml12"
                @click="toggleEdit(u.id)"
              >
                <i style="font-size: 12px;" class="edit icon" />
              </button>
            </td>
          </tr>
          <tr v-if="open == u.id" :key="u.id">
            <td align="center" colspan="100%">
              <div class="py12" v-if="loadingSubmissions">Loading</div>
              <div
                class="py12"
                v-if="!loadingSubmissions && submissions.length == 0"
              >
                User has no participation
              </div>
              <span class="py12" v-if="!loadingSubmissions">
                <div v-for="(s, six) in submissions" :key="s.id">
                  <p>
                    {{ six + 1 }}: {{ s.event.Title }}
                    <span class="c-aaa">{{ formatDate(s.created_at) }}</span>
                  </p>
                </div>
              </span>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import pagination from "../components/pagination";
import searchDropdown from "../components/search-dropdown";
let universities = [
  {
    id: 1,
    email: "tejaskamath@gmail.com",
    institute: "MBM Engineering College, Jodhpur"
  },
  {
    id: 2,
    email: "diyalanihemant@gmail.com",
    institute: "Amity University"
  },
  {
    id: 4,
    email: "meghnag1809@gmail.com",
    institute: "Central University of Kerala"
  },
  {
    id: 7,
    email: "meghna.goyal@outlook.in",
    institute: "Doon University"
  },
  {
    id: 8,
    email: "nishikslal@gmail.com",
    institute: "SPA- School of Planning and Architecture"
  },
  {
    id: 9,
    email: "rashni.square@gmail.com",
    institute: ""
  },
  {
    id: 10,
    email: "rama.acedge@gmail.com",
    institute: "MVP Samaj's College of Architecture"
  },
  {
    id: 11,
    email: "sonam@acedge.in",
    institute: ""
  },
  {
    id: 12,
    email: "gngsg9@gmail.com",
    institute: "VIT's PVPCOA, Pune"
  },
  {
    id: 13,
    email: "g_balakrishnan@hotmail.com",
    institute: ""
  },
  {
    id: 16,
    email: "ramsy_libra@yahoo.com",
    institute: ""
  },
  {
    id: 17,
    email: "igenius.shravan@gmail.com",
    institute: "DY Patil School of Architecture, Lohegaon"
  },
  {
    id: 18,
    email: "shravanv90@gmail.com",
    institute: "Alagappa Chettiar College of Engineering and Technology"
  },
  {
    id: 19,
    email: "rama.raghavan2018@gmail.com",
    institute: ""
  },
  {
    id: 20,
    email: "ethosindia.helpdesk@gmail.com",
    institute: "SPA- School of Planning and Architecture"
  },
  {
    id: 21,
    email: "iiidpresident@gmail.com",
    institute: ""
  },
  {
    id: 22,
    email: "neeteen@outlook.com",
    institute: ""
  },
  {
    id: 23,
    email: "asmita.sharma@chitkara.edu.in",
    institute: "Chitkara University, Patiala"
  },
  {
    id: 24,
    email: "anshrafa@gmail.com",
    institute: ""
  },
  {
    id: 25,
    email: "vkjoshi.interior17@chitkara.edu.in",
    institute: "Chitkara University, Patiala"
  },
  {
    id: 26,
    email: "arorasaloni074@gmail.com",
    institute: "Chitkara University, Patiala"
  },
  {
    id: 27,
    email: "sarora.interior17@chitkara.edu.in",
    institute: "Chitkara University"
  },
  {
    id: 28,
    email: "naini.interior18@chitkara.edu.in",
    institute: "Chitkara University"
  },
  {
    id: 29,
    email: "aps826704076@gmail.com",
    institute: "Chitkara University"
  },
  {
    id: 30,
    email: "abhaypratap.interior18@chitkara.edu.in",
    institute: "Chitkara University"
  },
  {
    id: 31,
    email: "rajnandini.minterior19@chitkara.edu.in",
    institute: "Chitkara University"
  },
  {
    id: 32,
    email: "kbhatt.interior17@chitkara.edu.in",
    institute: "Chitkara University"
  },
  {
    id: 33,
    email: "sakshi.interior18@chitkara.edu.in",
    institute: "Chitkara University, Patiala"
  },
  {
    id: 34,
    email: "harman.minterior19@chitkara.edu.in",
    institute: "Chitkara University"
  },
  {
    id: 35,
    email: "sukirti.interior18@chitkara.edu.in",
    institute: "Chitkara University"
  },
  {
    id: 36,
    email: "shakshi.minterior19@chitkara.edu.in",
    institute: "Chitkara University"
  },
  {
    id: 37,
    email: "susmita.minterior19@chitkara.edu.in",
    institute: "Chitkara University"
  },
  {
    id: 38,
    email: "anureet.minterior19@chitkara.edu.in",
    institute: "Chitkara University"
  },
  {
    id: 39,
    email: "abhatia.interior17@chitkara.edu.in",
    institute: "Chitkara University"
  },
  {
    id: 40,
    email: "ar.sarshazmi@gmail.com",
    institute: ""
  },
  {
    id: 41,
    email: "komal.gilda@gmail.com",
    institute: "Acharya Nagarjuna University, Guntur"
  },
  {
    id: 42,
    email: "suhitha.jala@gmail.com",
    institute: ""
  },
  {
    id: 43,
    email: "pramadamallepaddi@gmail.com",
    institute: "Acharya Nagarjuna University, Guntur"
  },
  {
    id: 44,
    email: "darepavani@gmail.com",
    institute: ""
  },
  {
    id: 45,
    email: "rajpuri1@gmail.com",
    institute: ""
  },
  {
    id: 46,
    email: "pramadaprincess@gmail.com",
    institute: "Acharya Nagarjuna University, Guntur"
  },
  {
    id: 47,
    email: "arswathipriya@gmai.com",
    institute: ""
  },
  {
    id: 48,
    email: "sharayu.ramteke026@gmail.com",
    institute:
      "SMMCA- Smt Manoramabai Mundle College of Architecture (LAD College)"
  },
  {
    id: 49,
    email: "asthachordia20012@gmail.com",
    institute:
      "SMMCA- Smt Manoramabai Mundle College of Architecture (LAD College)"
  },
  {
    id: 50,
    email: "ansh3333@gmail.com",
    institute: ""
  },
  {
    id: 51,
    email: "seemontadas99@gmail.com",
    institute:
      "SMMCA- Smt Manoramabai Mundle College of Architecture (LAD College), Nagpur"
  },
  {
    id: 52,
    email: "revathi.jilagam@gmail.com",
    institute: "Acharya Nagarjuna University, Guntur"
  },
  {
    id: 53,
    email: "smitha.naik@gmail.com",
    institute: ""
  },
  {
    id: 54,
    email: "rvpindiprolu@gmail.com",
    institute: ""
  },
  {
    id: 55,
    email: "sai.vivekanand@gmail.com",
    institute: ""
  },
  {
    id: 56,
    email: "jk.chirag@gmail.com",
    institute: ""
  },
  {
    id: 57,
    email: "ar.shahabuddin1982@gmail.com",
    institute: ""
  },
  {
    id: 58,
    email: "sdj3006@gmail.com",
    institute: ""
  },
  {
    id: 59,
    email: "ajam1996@gmail.com",
    institute: ""
  },
  {
    id: 60,
    email: "amrutayadav20000@gmail.com",
    institute: ""
  },
  {
    id: 61,
    email: "shabana.sadikot@gmail.com",
    institute: ""
  },
  {
    id: 62,
    email: "kribhagat78@gmail.com",
    institute: ""
  },
  {
    id: 63,
    email: "19sa02ar001@ppsu.ac.in",
    institute: "P P Savani University, Surat"
  },
  {
    id: 64,
    email: "dishitagoel1402@gmail.com",
    institute: ""
  },
  {
    id: 65,
    email: "rajamita@gmail.com",
    institute: ""
  },
  {
    id: 66,
    email: "amberrr0270@gmail.com",
    institute: ""
  },
  {
    id: 67,
    email: "tameerbharuch12@gmail.com",
    institute: ""
  },
  {
    id: 68,
    email: "sagardesai80887@yahoo.com",
    institute: "P P Savani University, Surat"
  },
  {
    id: 69,
    email: "18sd02id010@ppsu.ac.in",
    institute: "Not Listed"
  },
  {
    id: 70,
    email: "valerajhanvi9@gmail.com",
    institute: "Indian Institute of Interior Design (IIID)"
  },
  {
    id: 71,
    email: "rupa93.arch@gmail.com",
    institute: ""
  },
  {
    id: 72,
    email: "rajnikhemani97@gmail.com",
    institute:
      "SMMCA- Smt Manoramabai Mundle College of Architecture (LAD College), Nagpur"
  },
  {
    id: 73,
    email: "unique_interior@hotmail.com",
    institute: ""
  },
  {
    id: 74,
    email: "madhurs239@gmail.com",
    institute: "Not Listed"
  },
  {
    id: 75,
    email: "ifmaa1@gmail.com",
    institute: ""
  },
  {
    id: 76,
    email: "aabidraheem@gmail.com",
    institute: ""
  },
  {
    id: 77,
    email: "formsandspaces@gmail.com",
    institute: ""
  },
  {
    id: 78,
    email: "menonarc@gmail.com",
    institute: ""
  },
  {
    id: 79,
    email: "hrutulnasare777@gmail.com",
    institute: ""
  },
  {
    id: 80,
    email: "rvaarch@gmail.com",
    institute: ""
  },
  {
    id: 81,
    email: "shaileshmanke@gmail.com",
    institute: ""
  },
  {
    id: 82,
    email: "sathearchitects@gmail.com",
    institute: ""
  },
  {
    id: 83,
    email: "noor_2402@hotmail.com",
    institute: ""
  },
  {
    id: 84,
    email: "noorjahansheikh3@gmail.com",
    institute: ""
  },
  {
    id: 85,
    email: "designattitude97@gmail.com",
    institute: ""
  },
  {
    id: 86,
    email: "rannaparikh@yahoo.co.in",
    institute: ""
  },
  {
    id: 87,
    email: "gautamgandhi44@gmail.com",
    institute: ""
  },
  {
    id: 88,
    email: "ar.bhavikthakkar@gmail.com",
    institute: ""
  },
  {
    id: 89,
    email: "sheenu.khandelwal@gmail.com",
    institute: ""
  },
  {
    id: 90,
    email: "khanjan_epatra@yahoo.com",
    institute: ""
  },
  {
    id: 91,
    email: "samriddhimakker99@gmail.com",
    institute: "SPA Poornima University"
  },
  {
    id: 92,
    email: "silpas@gmail.com",
    institute: ""
  },
  {
    id: 93,
    email: "suresh.mistry33@gmail.com",
    institute: ""
  },
  {
    id: 94,
    email: "meghadesigns@gmail.com",
    institute: ""
  },
  {
    id: 95,
    email: "meghachokse@gmail.com",
    institute: ""
  },
  {
    id: 96,
    email: "ar.shivangi93@gmail.com",
    institute: ""
  },
  {
    id: 97,
    email: "madhuras95@gmail.com",
    institute:
      "SMMCA- Smt Manoramabai Mundle College of Architecture (LAD College), Nagpur"
  },
  {
    id: 98,
    email: "foram.stupa@gmail.com",
    institute: ""
  },
  {
    id: 99,
    email: "shahhetvi2001@gmail.com",
    institute: "INIFD"
  },
  {
    id: 100,
    email: "tushar1709@gmail.com",
    institute: ""
  },
  {
    id: 101,
    email: "mehulmdave@gmail.com",
    institute: ""
  },
  {
    id: 102,
    email: "mitalivora11@gmail.com",
    institute: ""
  },
  {
    id: 103,
    email: "egodes@gmail.com",
    institute: ""
  },
  {
    id: 104,
    email: "ksrakeshnair@gmail.com",
    institute: "Anna University"
  },
  {
    id: 105,
    email: "info@mihika.in",
    institute: ""
  },
  {
    id: 106,
    email: "ramji.vootada@gmail.com",
    institute: ""
  },
  {
    id: 107,
    email: "yashashreedeshmukh33@gmail.com",
    institute: "Not Listed"
  },
  {
    id: 108,
    email: "suhasgaonkar@gmail.com",
    institute: "Goa University"
  },
  {
    id: 109,
    email: "sushmitasubudhi@gmail.com",
    institute: "Not Listed"
  },
  {
    id: 110,
    email: "arc.anjan@gmail.com",
    institute: ""
  },
  {
    id: 111,
    email: "shraddhatamkhane11@gmail.com",
    institute: "Not Listed"
  },
  {
    id: 112,
    email: "pjain8314@gmail.com",
    institute: ""
  },
  {
    id: 113,
    email: "deepaksahajwani@gmail.com",
    institute: ""
  },
  {
    id: 114,
    email: "vaibhavrajsingh147@gmail.com",
    institute: "Amity University"
  },
  {
    id: 115,
    email: "sejal.govil@gmail.com",
    institute: "Amity University"
  },
  {
    id: 116,
    email: "tussuthepaap@gmail.com",
    institute: "Amity University, Jaipur"
  },
  {
    id: 117,
    email: "svselcouth@gmail.com",
    institute: "Amity University, Jaipur"
  },
  {
    id: 118,
    email: "rajputragini26@gmail.com",
    institute: "Amity University, Jaipur"
  },
  {
    id: 119,
    email: "vibhavrajsingh@gmail.com",
    institute: "Amity University, Jaipur"
  },
  {
    id: 120,
    email: "priya.spaceevolve@gmail.com",
    institute: ""
  },
  {
    id: 121,
    email: "ffrenzyinteriors@gmail.com",
    institute: ""
  },
  {
    id: 122,
    email: "yj68671@gmail.com",
    institute: "Jawaharlal Nehru Institute of Advanced Studies (JNIAS) - SPA"
  },
  {
    id: 123,
    email: "2subscribe0nly@gmail.com",
    institute: ""
  },
  {
    id: 124,
    email: "mishra.archi99@gmail.com",
    institute: "Central University of Rajasthan"
  },
  {
    id: 125,
    email: "surashmie@yahoo.com",
    institute: "Lady Amrithal Daga College, Nagpur"
  },
  {
    id: 126,
    email: "gdivyanshi26@gmail.com",
    institute: "Bharti Vidyapeeth College of Architecture"
  },
  {
    id: 127,
    email: "anjalisdesigns@gmail.com",
    institute: ""
  },
  {
    id: 128,
    email: "bansari9499@gmail.com",
    institute: ""
  },
  {
    id: 129,
    email: "akhila2320@gmail.com",
    institute: "Dr M G R Educational And Research Institute"
  },
  {
    id: 130,
    email: "manojmadhu23@gmail.com",
    institute: ""
  },
  {
    id: 131,
    email: "dhaval@i-con.co.in",
    institute: ""
  },
  {
    id: 132,
    email: "sanyammehra@gmail.com",
    institute: ""
  },
  {
    id: 133,
    email: "aestheticpointjaipur7@gmail.com",
    institute: ""
  },
  {
    id: 134,
    email: "naraniashewta@gmail.com",
    institute: ""
  },
  {
    id: 135,
    email: "tulisha3465@gmail.com",
    institute: "Biju Patnaik University of Technology"
  },
  {
    id: 136,
    email: "srinteriordesigns9@gmail.com",
    institute: ""
  },
  {
    id: 137,
    email: "amitsheth.india@gmail.com",
    institute: ""
  },
  {
    id: 138,
    email: "poshdesigns.studio@gmail.com",
    institute: ""
  },
  {
    id: 139,
    email: "gauri20chandak@gmail.com",
    institute: "Lady Amrithal Daga College, Nagpur"
  },
  {
    id: 140,
    email: "ar.manogyagupta@gmail.com",
    institute: ""
  },
  {
    id: 141,
    email: "jaiswalpriya1794@gmail.com",
    institute: "Not Listed"
  },
  {
    id: 142,
    email: "rohitkoiri70@gmail.com",
    institute: "IIEST - Indian Institute of Engineering Science and Technology"
  },
  {
    id: 143,
    email: "archnanda@gmail.com",
    institute: ""
  },
  {
    id: 144,
    email: "vaidyaashish@hotmail.com",
    institute: ""
  },
  {
    id: 145,
    email: "tulna@aakrutiarchitects.com",
    institute: ""
  },
  {
    id: 146,
    email: "avantika@spaces-within.com",
    institute: ""
  },
  {
    id: 147,
    email: "ar.ramnath.nayak@gmail.com",
    institute: ""
  },
  {
    id: 148,
    email: "nehaabraham98@gmail.com",
    institute: "MSRIT - M.S. Ramaiah Institute of Technology"
  },
  {
    id: 149,
    email: "girishbajaj1611@gmail.com",
    institute: "Not Listed"
  },
  {
    id: 150,
    email: "naina.reddy8@gmail.com",
    institute: ""
  },
  {
    id: 151,
    email: "bittuparani9@gmail.com",
    institute: ""
  },
  {
    id: 152,
    email: "sadabmalik.sm6@gmail.com",
    institute: ""
  },
  {
    id: 153,
    email: "naina@makespacearchitects.com",
    institute: ""
  },
  {
    id: 154,
    email: "a4squaredesign@gmail.com",
    institute: ""
  },
  {
    id: 155,
    email: "hariramananth@gmail.com",
    institute: "National Institute of Technology, Tiruchirappalli"
  },
  {
    id: 156,
    email: "sa.v.140498@gmail.com",
    institute: ""
  },
  {
    id: 157,
    email: "tanvee.abhyankar@gmail.com",
    institute: "Not Listed"
  },
  {
    id: 158,
    email: "2019bvidsomya7723@poornima.edu.in",
    institute: "SPA Poornima University"
  },
  {
    id: 159,
    email: "spacestudioarch@gmail.com",
    institute: ""
  },
  {
    id: 160,
    email: "pranalisakhala@gmail.com",
    institute: ""
  },
  {
    id: 161,
    email: "vaishnaviagale10@gmail.com",
    institute: "Not Listed"
  },
  {
    id: 162,
    email: "ashfaqa1@hotmail.com",
    institute: ""
  },
  {
    id: 163,
    email: "shruti.gupta@studiosga.co.in",
    institute: ""
  },
  {
    id: 164,
    email: "ar.ritikagodha@gmail.com",
    institute: ""
  },
  {
    id: 165,
    email: "suruchiranadive@gmail.com",
    institute: ""
  },
  {
    id: 166,
    email: "lokeshpakhale@gmail.com",
    institute: ""
  },
  {
    id: 167,
    email: "equinoxd.sign@gmail.com",
    institute: ""
  },
  {
    id: 168,
    email: "prakashalka24@gmail.com",
    institute: "Assam Science and Technology University"
  },
  {
    id: 169,
    email: "kaavyamaram@gmail.com",
    institute: ""
  },
  {
    id: 170,
    email: "sh.kumarag@gmail.com",
    institute: ""
  },
  {
    id: 171,
    email: "bhargavagarima2@gmail.com",
    institute: "Amity University"
  },
  {
    id: 172,
    email: "hriatiikonic@gmail.com",
    institute: "Not Listed"
  },
  {
    id: 173,
    email: "bhumikachainani@gmail.com",
    institute: ""
  },
  {
    id: 174,
    email: "nagappan@designqubearchitects.com",
    institute: ""
  },
  {
    id: 175,
    email: "rashmi.narayanan27@gmail.com",
    institute: "Not Listed"
  },
  {
    id: 176,
    email: "kmmyasir@gmail.com",
    institute: ""
  },
  {
    id: 177,
    email: "jdwadia@gmail.com",
    institute: "INIFD"
  },
  {
    id: 178,
    email: "abhichandani123@gmail.com",
    institute: "Not Listed"
  },
  {
    id: 179,
    email: "vickysharma201099@gmail.com",
    institute: "Annamalai University"
  },
  {
    id: 180,
    email: "kuljotgujral@gmail.com",
    institute: ""
  },
  {
    id: 181,
    email: "kritikrshnsjc@gmail.com",
    institute: "College of Architecture and Design, Mody University"
  },
  {
    id: 182,
    email: "agarwalpriya2907@gmail.com",
    institute: ""
  },
  {
    id: 183,
    email: "pareekharshita17@gmail.com",
    institute: "Not Listed"
  },
  {
    id: 184,
    email: "ananyasinghr2@gmail.com",
    institute: "College of Architecture and Design, Mody University"
  },
  {
    id: 185,
    email: "tinasisodia22@gmail.com",
    institute: "College of Architecture and Design, Mody University, Sikar"
  },
  {
    id: 186,
    email: "shreyakhandelwal0001@gmail.com",
    institute: "Mody University of Science and Technology"
  },
  {
    id: 187,
    email: "meenal1727@gmail.com",
    institute: "Mody University of Science and Technology"
  },
  {
    id: 188,
    email: "kartik.sharma.3141@gmail.com",
    institute: ""
  },
  {
    id: 189,
    email: "abuzar007shaikh@gmail.com",
    institute: "INIFD"
  },
  {
    id: 190,
    email: "sanchetiprachi3@gmail.com",
    institute: ""
  },
  {
    id: 191,
    email: "priyankatalin@gmail.com",
    institute: "INIFD"
  },
  {
    id: 192,
    email: "hiteshamarseda@gmail.com",
    institute: "INIFD"
  },
  {
    id: 193,
    email: "neha612@gmail.com",
    institute: ""
  },
  {
    id: 194,
    email: "saili3raipure@gmail.com",
    institute: ""
  },
  {
    id: 195,
    email: "manjuduseja796@gmail.com",
    institute: ""
  },
  {
    id: 196,
    email: "121meghagupta@gmail.com",
    institute: "Not Listed"
  },
  {
    id: 197,
    email: "disneysharma20@gmail.com",
    institute: "INIFD"
  },
  {
    id: 198,
    email: "shivani.pachchigar@scet.ac.in",
    institute: "SCET- Sarvajanik Education Society"
  },
  {
    id: 199,
    email: "ananwithjams@gmail.com",
    institute: "College of Architecture and Design, Mody University, Sikar"
  },
  {
    id: 200,
    email: "manisha.gotmare@gmail.com",
    institute: "INIFD"
  },
  {
    id: 201,
    email: "sisodiaprathamesh8.com@gmail.com",
    institute: "College of Architecture and Design, Mody University, Sikar"
  },
  {
    id: 202,
    email: "sisodiyalakshita30@gmail.com",
    institute: "Mody University, Jaipur"
  },
  {
    id: 203,
    email: "mehertajans21@gmail.com",
    institute: "Not Listed"
  },
  {
    id: 204,
    email: "maitryshah2399@gmail.com",
    institute: "Not Listed"
  },
  {
    id: 205,
    email: "kratikabulani010@gmail.com",
    institute: "INIFD"
  },
  {
    id: 206,
    email: "bhargavabulbul07@gmail.com",
    institute: "Jiwaji University"
  },
  {
    id: 207,
    email: "wablepankaj@gmail.com",
    institute: ""
  },
  {
    id: 208,
    email: "ishapedamkar38@gmail.com",
    institute: "Indian Institute of Technology Bombay"
  },
  {
    id: 209,
    email: "bhavikaparihar.99@gmail.com",
    institute: "Not Listed"
  },
  {
    id: 210,
    email: "jaysharma1995@gmail.com",
    institute: "Not Listed"
  },
  {
    id: 211,
    email: "awaiz.walile36@gmail.com",
    institute: ""
  },
  {
    id: 212,
    email: "krishnaphaldesai@rediffmail.com",
    institute: ""
  },
  {
    id: 213,
    email: "jeswanibhushan@gmail.com",
    institute: ""
  },
  {
    id: 214,
    email: "ishabandagale06@gmail.com",
    institute: "Not Listed"
  },
  {
    id: 215,
    email: "unnati.ladde.98@gmail.com",
    institute: "Not Listed"
  },
  {
    id: 216,
    email: "kiranarjunsubhane@gmail.com",
    institute: "INIFD, Navi Mumbai"
  },
  {
    id: 217,
    email: "vsmotiwala119@gmail.com",
    institute: ""
  },
  {
    id: 219,
    email: "akruti.khandelia@gmail.com",
    institute: ""
  },
  {
    id: 220,
    email: "jinalp707@gmail.com",
    institute: "INIFD, Navi Mumbai"
  },
  {
    id: 221,
    email: "vaishaliverma998@gmail.com",
    institute: "Not Listed"
  },
  {
    id: 222,
    email: "mona_lall@yahoo.co.uk",
    institute: "INIFD"
  },
  {
    id: 223,
    email: "pj1533108@gmail.com",
    institute: ""
  },
  {
    id: 224,
    email: "nirjpatil2000@gmail.com",
    institute: "MATS University"
  },
  {
    id: 225,
    email: "prashantjangidrh18@gmail.com",
    institute: ""
  },
  {
    id: 226,
    email: "preksha11sp@gmail.com",
    institute: ""
  },
  {
    id: 227,
    email: "radha1991@gmail.com",
    institute: ""
  },
  {
    id: 228,
    email: "snehal.sontakke@gmail.com",
    institute: ""
  },
  {
    id: 229,
    email: "vrparch@gmail.com",
    institute: ""
  },
  {
    id: 230,
    email: "preechin@yahoo.com",
    institute: ""
  },
  {
    id: 231,
    email: "aashlesha.b@gmail.com",
    institute: "Vidya Vardhan Institute of Design Environment and Architecture"
  },
  {
    id: 232,
    email: "hunnythumar711@gmail.com",
    institute:
      "International Institute of Fashion Design and Interior Design_INIFD)"
  },
  {
    id: 233,
    email: "sanjanadokhale744@gmail.com",
    institute: ""
  },
  {
    id: 234,
    email: "pawaskar1@gmail.com",
    institute: ""
  },
  {
    id: 235,
    email: "nancyagrawal199@gmail.com",
    institute: "Not Listed"
  },
  {
    id: 236,
    email: "kus2in@rediffmail.com",
    institute: ""
  },
  {
    id: 237,
    email: "kaneavedashree@gmail.com",
    institute:
      "International Institute of Fashion Design and Interior Design_INIFD)"
  },
  {
    id: 238,
    email: "priyanshuchhajedjain2001@gmail.com",
    institute: "Amity University"
  },
  {
    id: 239,
    email: "ashish.kala@gmail.com",
    institute: ""
  },
  {
    id: 240,
    email: "jainakshat1601@gmail.com",
    institute: "Amity University"
  },
  {
    id: 241,
    email: "priyankachoudhary63131@gmail.com",
    institute: "Amity University"
  },
  {
    id: 242,
    email: "jainharshitamdgr@gmail.com",
    institute:
      "Amity School of Architecture & Planning, Amity University Rajasthan  "
  },
  {
    id: 243,
    email: "yeswanthjujjuru2002@gmail.com",
    institute: "Amity University"
  },
  {
    id: 244,
    email: "dasaripavankumar3037@gmail.com",
    institute: "Amity University"
  },
  {
    id: 245,
    email: "ar.vijayprashant@gmail.com",
    institute: "Amity University"
  },
  {
    id: 246,
    email: "chhajedpriyanshu4@gmail.com",
    institute: "Amity University"
  },
  {
    id: 247,
    email: "sayedheenakousar786@gmail.com",
    institute: "Amity University"
  },
  {
    id: 248,
    email: "sudkumawat007@gmail.com",
    institute: "Amity University"
  },
  {
    id: 249,
    email: "sushantrathi777@gmail.com",
    institute: "Amity University"
  }
];
export default {
  name: "user-search",
  components: { searchDropdown, pagination },
  props: {
    showCleanup: { default: false }
  },
  data() {
    return {
      users: [],
      count: 0,
      open: "",
      edit: "",
      submissions: [],
      loadingSubmissions: false,
      form: {
        levelOfEducation: "",
        field: "",
        yearOfGraduation: "",
        type: "",
        institute: "",
        zone: "",
        state: "",
        city: "",
        country: "",
        connectaidJobSeeker: "",
        acegateLearner: "",
        acceptingMassmail: ""
      },
      replace: {
        institute: "",
        city: "",
        acceptingMassmail: "",
        type: ""
      },
      yogeq: "",
      search: "",
      page: 0,
      dbUnis: [],
      loading: false,
      zones: ["North", "South", "East", "West"],
      educationLevels: [
        "Middle School",
        "High School",
        "Diploma",
        "Undergraduate",
        "Postgraduate",
        "Doctorate"
      ],
      userTypes: ["Student", "Professional", "Faculty"]
    };
  },
  watch: {
    // form: {
    // 	handler: function () {
    // 		this.page = 0
    // 		this.searchUsers(0)
    // 	},
    // 	deep: true
    // },
    open: function(val) {
      if (val == "") {
        return;
      }
      this.loadingSubmissions = true;
      this.$req
        .get(`event-submissions?users.id=${val}`, {
          headers: {
            Authorization: `Bearer ${this.$bus.token}`
          }
        })
        .then(r => {
          const submissions = r.data.filter(
            s => s.event && Object.keys(s.event).length > 0
          );
          // Getting old registrations
          const user = this.users.find(u => u.id == val)
          const email = user.email
          this.$req
            .get(
              `getOldEventRegistrations?email=${email}`,
              this.$bus.headers
            )
            .then(r => {
               this.submissions = [...r.data, ...submissions]
               this.loadingSubmissions = false;
            })
            .catch(e => console.log(e))
        })
        .catch(e => console.log(e));
    },
    page: function(v) {
      this.searchUsers(v);
    }
  },
  mounted() {
    this.$req
      .get("universities?_limit=-1")
      .then(r => {
        this.dbUnis = r.data;
      })
      .catch(e => console.log(e));
    this.searchUsers();
  },
  computed: {
    pages: function() {
      return Math.ceil(this.count / 30);
    },
    filteredFields: function() {
      return [
        "Architecture",
        "Interior Design",
        "Civil Engineering",
        "Planning",
        "Design",
        "Other"
      ];
    },
    years: function() {
      return new Array(70).fill(1).map((n, i) => {
        return 1960 + i;
      });
    },
    filteredUnis: function() {
      let unis = this.dbUnis;
      if (this.form.zone) {
        unis = unis.filter(u => u.zone == this.form.zone);
      }
      if (this.form.state) {
        unis = unis.filter(u => u.state == this.form.state);
      }
      if (this.form.city) {
        unis = unis.filter(u => u.city == this.form.city);
      }
      if (this.form.field) {
        let key = {
          Architecture: "#a",
          "Interior Design": "#d",
          Planning: "#p",
          "Civil Engineering": "#c",
          Design: "#d"
        };

        if (Object.keys(key).includes(this.form.field)) {
          unis = unis.filter(u => {
            if (u.categories) {
              return u.categories.includes(key[this.form.field]);
            }
            return false;
          });
        }
      }
      return unis;
    },
    filteredStates: function() {
      let zone = this.form.zone;
      let unis = this.dbUnis;
      if (zone) {
        unis = unis.filter(u => u.zone == zone);
      }
      let states = unis
        .map(u => u.state)
        .filter((v, i, s) => s.indexOf(v) == i)
        .sort();
      return states;
    },
    filteredCities: function() {
      let cities = this.filteredUnis
        .map(u => u.city)
        .filter((v, i, s) => s.indexOf(v) == i)
        .sort();
      return cities;
    }
  },
  methods: {
    clearForm: function() {
      let keys = Object.keys(this.form);
      for (let k of keys) {
        this.form[k] = "";
      }
      this.search = "";
    },
    formatDate: function(date) {
      return moment(date).format("HH:mm on DD-MMM-YYYY");
    },
    downloadUsers: function(repl = false) {
      let form = { ...this.form };

      if (this.search) {
        form["_or"] = [
          {
            name_contains: this.search
          },
          {
            email_contains: this.search
          },
          {
            institute_contains: this.search
          },
          {
            notListedInstitute_contains: this.search
          },
          {
            location_contains: this.search
          }
        ];
      }

      form = this.$bus.processForm(form, this.dbUnis);

      for (let k of Object.keys(form)) {
        if (form[k] == "") {
          delete form[k];
        }
      }

      this.$req
        .post(
          "getUsers",
          {
            filter: form,
            limit: this.count,
            page: 0
          },
          this.$bus.headers
        )
        .then(r => {
          let data = r.data.users;
          if (!repl) {
            this.downloadCsv(data);
          } else {
            this.replaceUnis(data.map(u => u.id));
          }
        })
        .catch(e => console.log(e));
    },
    searchUsers: function(page = 0) {
      let form = { ...this.form };

      if (this.search) {
        form["_or"] = [
          {
            name_contains: this.search
          },
          {
            companyName_contains: this.search
          },
          {
            email_contains: this.search
          },
          {
            institute_contains: this.search
          },
          {
            notListedInstitute_contains: this.search
          },
          {
            location_contains: this.search
          }
        ];
      }

      form = this.$bus.processForm(form, this.dbUnis, this.yogeq);

      for (let k of Object.keys(form)) {
        if (form[k] == "") {
          delete form[k];
        }
      }

      // let filter = qs.stringify({
      //   _where: form
      // })
      // this.$req.get(`users/count?${filter}&_limit=-1`, this.$bus.headers)
      // 	.then(r => {
      // 		this.count = r.data
      // 		console.log('Count', this.count)
      // 	})
      // 	.catch(e => console.log(e))
      // this.$req.get(`users`, {
      // }, this.$bus.headers)
      // .then(r => console.log(r))
      // .catch(e => console.log(e))
      this.loading = true;
      this.$req
        .post(
          "getUsers",
          {
            filter: form,
            limit: 30,
            page
          },
          this.$bus.headers
        )
        .then(r => {
          this.loading = false;
          this.users = r.data.users;
          this.count = r.data.count;
        })
        .catch(e => {
          this.loading = false;
          console.log(e);
        });
    },
    updateUser: function(userId, obj) {
      let key = Object.keys(obj)[0];
      let val = obj[key];
      this.$baseReq
        .put(`users/${userId}`, obj, this.$bus.headers)
        .then(() => {
          let ix = this.users.findIndex(u => u.id == userId);
          let user = this.users[ix];
          this.$set(this.users[ix], key, val);
          this.$bus.notify(
            `${user.name ? user.name : user.email}'s info updated`
          );
        })
        .catch(e => console.log(e));
    },
    toggleEdit: function(userId) {
      if (this.edit) {
        this.edit = "";
      } else {
        this.edit = userId;
      }
    },
    toggleOpen: function(userId) {
      if (this.open) {
        this.open = "";
      } else {
        this.open = userId;
      }
    },
    runUpdateLoop: async function() {
      for (let u of universities) {
        await this.$req
          .put(
            `users/${u.id}`,
            {
              institute: u.institute
            },
            this.$bus.headers
          )
          .catch(e => console.log(e));
      }
    },
    replaceUnis: async function(ids) {
      let y = confirm(
        `Are you sure? ${ids.length} users will have their information edited`
      );
      if (y) {
        let form = { ...this.replace };
        form["location"] = form["city"];
        delete form["city"];
        for (let k of Object.keys(form)) {
          if (form[k] == "") {
            delete form[k];
          }
        }
        for (let id of ids) {
          await this.$baseReq
            .put(`users/${id}`, form, this.$bus.headers)
            .catch(e => console.log(e));
        }
        this.searchUsers(0);
      }
    },
    downloadCsv: function(json) {
      var csv = [];
      // var maxlen = Math.max(...json.map(j => Object.keys(j).length))
      // var maxjson = json.filter(j => Object.keys(j).length == maxlen)
      if (json.length) {
        var keys = [
          "name",
          "email",
          "contact",
          "type",
          "field",
          "institute",
          "yearOfGraduation",
          "location",
          "acceptingMassmail"
        ]; // Object.keys(maxjson[0])
        csv.push(keys.join(","));
        json.forEach(item => {
          let vals = keys.map(key => '"' + item[key] + '"' || "");
          csv.push(vals.join(","));
        });
      }

      csv = csv.join("\n");
      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      hiddenElement.target = "_blank";
      hiddenElement.download =
        moment().format("HH:mm DD-MM-YY") + "-user-export.csv";
      hiddenElement.click();
    }
  }
};
</script>
